import {closeModal, MODAL_IDS, openModal} from '@Components/modal-container-deprecated';
import type {CropperPanelProps} from '@Panels/cropper-panel';
import type {MessageModalProps} from '@Modals/message-modal';
import {closeMessageModal, openMessageModal} from '@Modals/message-modal';
import {openConfirmDeleteModal} from '@Modals/confirm-delete-modal';
import type {ConfirmDeletePanelProps} from '@Panels/confirm-delete-panel';
import type {TrimAudioModalProps} from '@Modals/trim-audio-modal';
import {openTrimAudioModal} from '@Modals/trim-audio-modal';
import type {TrimVideoModalProps} from '@Modals/trim-video-modal';
import {openTrimVideoModal} from '@Modals/trim-video-modal';
import type {SaveConflictModalProps} from '@Modals/save-conflict-modal';
import {openSaveConflictModal} from '@Modals/save-conflict-modal';
import {Modal} from '@Components/modal-deprecated';
import type {ResizeModalProps} from '@Modals/resize-modal';
import {openResizeModal} from '@Modals/resize-modal';
import {openChangePlanModal} from '@Modals/change-plan-modal';
import type {MaskingModalProps} from '@Modals/masking-modal';
import {openMaskingModal} from '@Modals/masking-modal';
import type {CompleteSignupModalProps} from '@Modals/complete-signup-modal';
import {openCompleteSignupModal} from '@Modals/complete-signup-modal';
import type {ConnectSSOAccountPanelProps} from '@Panels/connect-sso-account-panel';
import {openConnectSSOAccountModal} from '@Modals/connect-sso-account-modal';
import {openTwoFactorAuthenticationVerificationModal} from '@Modals/two-factor-authentication-verification-modal/two-factor-authentication-verification-modal.library';
import type {ManageTwoFactorAuthenticationModalProps} from '@Modals/manage-two-factor-authentication-modal';
import {openManageTwoFactorAuthenticationModal} from '@Modals/manage-two-factor-authentication-modal/manage-two-factor-authentication-modal.library';
import type {PasswordVerificationPanelProps} from '@Panels/password-verification-panel/password-verification-panel.types';
import {openPasswordVerificationModal} from '@Modals/password-verification-modal/password-verification-modal.library';
import type {TwoFactorAuthenticationVerificationPanelProps} from '@Panels/two-factor-authentication-verification-panel/two-factor-authentication-verification-panel.types';
import {openAccountDeletionModal} from '@Modals/account-deletion-modal/account-deletion-modal-library';
import type {AccountDeletionModalProps} from '@Modals/account-deletion-modal/account-deletion-modal.types';
import type {ErrorModalProps} from '@Modals/error-modal';
import {openErrorModal} from '@Modals/error-modal';
import type {ConfirmationModalProps} from '@Modals/confirmation-modal';
import {openConfirmationModal} from '@Modals/confirmation-modal';
import {openCropperModal} from '@/components/modals/cropper-modal/cropper-modal.library';
import {HomepageAiFeaturesModalProps, openHomepageAiFeaturesModal} from '@Modals/homepage-ai-features-modal';
import {HomepageStartCreatingModalProps, openHomepageStartCreatingModal} from '@Modals/homepage-start-creating-modal';

window.PMW.mergePMW(PMW, {
  openErrorModal(props: ErrorModalProps) {
    openErrorModal(props);
  },

  openRemoveBackgroundPurchaseModal(options: any = {}) {
    openModal(MODAL_IDS.REMOVE_BG_PURCHASE_MODAL_ID, options);
  },

  openMessageModal(props: MessageModalProps) {
    openMessageModal(props);
  },

  closeMessageModal() {
    closeMessageModal();
  },

  openCropperModal(props: CropperPanelProps) {
    openCropperModal(props);
  },

  openTrimAudioModal(props: TrimAudioModalProps) {
    openTrimAudioModal(props);
  },

  openTrimVideoModal(props: TrimVideoModalProps) {
    openTrimVideoModal(props);
  },

  /**
   * Closes the modal
   * @param {string} id
   */
  closeModal(id: string) {
    if (!Object.values(MODAL_IDS).includes(id)) {
      return;
    }

    closeModal(id);
  },

  openConfirmDeleteModal(props: ConfirmDeletePanelProps) {
    openConfirmDeleteModal(props);
  },

  /**
   *
   * @param {Object} [options]
   * @param {string} [options.modalTitle]
   * @param {string} [options.modalClasses]
   * @param {Object} [options.modalContent]
   * @param {boolean} [options.closeOnModalBodyClick]
   *
   */
  openMenuListModal(options = {}) {
    openModal(MODAL_IDS.MENU_LIST_MODAL, options);
  },

  openSettingsModal(options = {}) {
    openModal(MODAL_IDS.SETTINGS_MODAL_ID, options);
  },

  openSaveConflictModal(options: SaveConflictModalProps) {
    openSaveConflictModal(options);
  },

  openResizeModal(options: ResizeModalProps) {
    openResizeModal(options);
  },

  async openChangePlanModal() {
    if (await window.PMW.showSubscriptionHandlingInfo()) {
      return;
    }
    openChangePlanModal();
  },

  openMaskingModal(options: MaskingModalProps) {
    openMaskingModal(options);
  },

  openConnectSSOAccountModal(options: ConnectSSOAccountPanelProps) {
    openConnectSSOAccountModal(options);
  },

  openTwoFactorAuthenticationVerificationModal(options: TwoFactorAuthenticationVerificationPanelProps) {
    openTwoFactorAuthenticationVerificationModal(options);
  },

  openPasswordVerificationModal(options: PasswordVerificationPanelProps) {
    openPasswordVerificationModal(options);
  },

  openManageTwoFactorAuthenticationModal(options: ManageTwoFactorAuthenticationModalProps) {
    openManageTwoFactorAuthenticationModal(options);
  },

  /**
   * Disable modals from opening
   */
  disableModals() {
    Modal.disableModals();
  },

  /**
   * @param opts
   */
  openCompleteSignupModal(opts: CompleteSignupModalProps) {
    openCompleteSignupModal(opts);
  },

  openAccountDeletionModal(options: AccountDeletionModalProps) {
    openAccountDeletionModal(options);
  },

  openConfirmationModal(options: ConfirmationModalProps) {
    openConfirmationModal(options);
  },
});
