import type {ReactElement} from 'react';
import React from 'react';
import {Modal} from '@Components/modal';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {LazyPanel} from '@Components/lazy-panel';
import type {CompleteSignupPanelProps} from '@Panels/complete-signup-panel/complete-signup-panel';
import useWindowSize from '@Hooks/useWindowSize';
import {useAppSelector} from '@/hooks';

const PANEL_ID = 'complete-signup-modal-panel';

export type CompleteSignupModalProps = CompleteSignupPanelProps;

export default function CompleteSignupModal(): ReactElement {
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.COMPLETE_SIGNUP_MODAL];
  });
  const {windowWidth} = useWindowSize();

  const height = windowWidth > 560 ? '422px' : '406px';
  const width = '488px';

  return (
    <Modal modalId={MODALS_IDS.COMPLETE_SIGNUP_MODAL} panelId={PANEL_ID} modalHeight={height} modalWidth={width}>
      <LazyPanel
        panelDirectoryName="complete-signup-panel"
        panelProps={{
          ...modalData.panelProps,
          panelId: PANEL_ID,
        }}
      />
    </Modal>
  );
}

export const openCompleteSignupModal = (props: CompleteSignupModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.COMPLETE_SIGNUP_MODAL,
      panelProps: props,
      isDismissible: false,
    })
  );
};
