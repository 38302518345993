import type {TwoFactorAuthenticationVerificationPanelProps} from '@Panels/two-factor-authentication-verification-panel/two-factor-authentication-verification-panel.types';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';

export const openTwoFactorAuthenticationVerificationModal = (props: TwoFactorAuthenticationVerificationPanelProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.TWO_FACTOR_AUTHENTICATION_VERIFICATION_MODAL,
      panelProps: props,
    })
  );
};
