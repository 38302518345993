import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import type {AccountDeletionModalProps} from '@Modals/account-deletion-modal/account-deletion-modal.types';
import {MODALS_IDS} from '@Components/modal-container';

export const openAccountDeletionModal = (props: AccountDeletionModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.ACCOUNT_DELETION_MODAL,
      panelProps: props,
    })
  );
};
