import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import type {ReactElement} from 'react';
import React from 'react';

export default function ChangePlanModal(): ReactElement {
  const modalId = MODALS_IDS.CHANGE_PLAN_MODAL;
  const panelId = `${modalId}-panel`;

  return (
    <Modal modalId={modalId} panelId={panelId} modalHeight="auto" modalWidth="600px">
      <LazyPanel panelDirectoryName="change-plan-modal" panelProps={{panelId}} />
    </Modal>
  );
}

export const openChangePlanModal = (): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.CHANGE_PLAN_MODAL,
      panelProps: {},
    })
  );
};
