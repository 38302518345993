import type {PasswordVerificationPanelProps} from '@Panels/password-verification-panel/password-verification-panel.types';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';

export const openPasswordVerificationModal = (props: PasswordVerificationPanelProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.PASSWORD_VERIFICATION_MODAL,
      panelProps: props,
    })
  );
};
