import type {ReactElement} from 'react';
import React from 'react';
import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import {Modal} from '@Components/modal';
import {LazyPanel} from '@Components/lazy-panel';
import type {ConnectSSOAccountPanelProps} from '@Panels/connect-sso-account-panel';
import {CONNECT_SSO_ACCOUNT_PANEL_ID} from '@Panels/connect-sso-account-panel';
import {useAppSelector} from '@/hooks';

const DEFAULT_MODAL_WIDTH = '472px';

export default function ConnectSSOAccountModal(): ReactElement {
  const panelProps = useAppSelector((state) => {
    return state.modals.modalsHashmap[MODALS_IDS.CONNECT_SSO_ACCOUNT_MODAL].panelProps as ConnectSSOAccountPanelProps;
  });

  return (
    <Modal modalId={MODALS_IDS.CONNECT_SSO_ACCOUNT_MODAL} panelId={panelProps.panelId ?? CONNECT_SSO_ACCOUNT_PANEL_ID} modalHeight="auto" modalWidth={DEFAULT_MODAL_WIDTH}>
      <LazyPanel
        panelDirectoryName="connect-sso-account-panel"
        panelProps={
          {
            ...panelProps,
            panelId: panelProps.panelId,
          } as ConnectSSOAccountPanelProps
        }
      />
    </Modal>
  );
}

export const openConnectSSOAccountModal = (props: ConnectSSOAccountPanelProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.CONNECT_SSO_ACCOUNT_MODAL,
      panelProps: props,
    })
  );
};
