import {openPanelInModal} from '@Components/modal-container/modal-container-reducer';
import {MODALS_IDS} from '@Components/modal-container';
import type {ManageTwoFactorAuthenticationModalProps} from '@Modals/manage-two-factor-authentication-modal/manage-two-factor-authentication-modal';

export const openManageTwoFactorAuthenticationModal = (props: ManageTwoFactorAuthenticationModalProps): void => {
  window.PMW.redux.store.dispatch(
    openPanelInModal({
      modalId: MODALS_IDS.MANAGE_TWO_FACTOR_AUTHENTICATION_MODAL,
      panelProps: props,
    })
  );
};
